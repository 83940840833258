.rolam-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap:5rem;
    margin:3rem auto 10rem auto;
    width: 90%;
    padding: 2rem 0rem;
}
.rolam-text {
    max-width: 700px;
}
.rolam-image {
    transform: rotateY(180deg);
    filter: drop-shadow(-20px 5px 4px rgb(29, 29, 29));
}
.social {
    display: flex;
    gap:1rem;
    font-size: 1.9rem;
}
.social a {
    text-decoration: none;
    color:white;
}
.github, .linkedin {
    transition: .2s;
}
.github:hover, .linkedin:hover {
    cursor: pointer;
    transform: scale(1.1);
    transition: .2s;
}
.rolam-text p:nth-child(5) {
    font-weight: 600;
}
.rolam-text p:nth-child(1) {
    color:var(--lightblue);
    font-weight: 600;
    padding: 0;
    margin: 0;
}
.rolam-text h2 {
    margin: 0.2rem 0rem;
    font-size: 1.7rem;
}
.tanulmanyok-content {
    display: flex;
    justify-content: center;
    gap:5rem;
    margin: 0 auto 10rem auto;
    width: 90%;
}
.rolam-career {
    width: 1000px;
}
.rolam-career .light {
    color:var(--darkgrey)
}
.kepessegek-content {
    display: flex;
    justify-content: center;
    gap:5rem;
    padding: 3rem 0rem;
    width: 70%;
    margin: 0rem auto 8rem auto;
}
.rolam-skills {
    display: flex;
    flex-direction: column;
    gap:1.5rem;
}


/* Bemutatkozás section. */
@media(max-width:1015px) {
    .rolam-content {
        flex-direction: column;
    }
}
/* Tanulmányok, skills section. */
@media(max-width:1413px) {
    .tanulmanyok-content {
        flex-direction: column;
        align-items: center;
        gap:2rem;
    }
    .rolam-career {
        width: 100%;
    }
    .kepessegek-content {
        flex-direction: column;
        align-items: center;
        gap:2rem;
        padding: 0rem;
    }
    .kepessegek-content .rolam-text {
        margin-bottom: 1rem;
    }
    .rolam-skills-frontend, .rolam-skills-backend {
        justify-content: center;
    }
}
@media(max-width:1015px) {
    .rolam-content,.kepessegek-content {
        margin-bottom: 3rem;
    }
    .tanulmanyok-content {
        margin-bottom: 5rem;
    }
}