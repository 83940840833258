@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');
:root {
    --lightblue: #A2B2EE;
    --lightgrey: #2E3239;
    --darkblue: #5F7ADB;
    --darkgrey: #26292B;
}
html {
    scroll-behavior: smooth;
}
* {
    box-sizing: border-box;
}
body {
    margin: 0;
    padding: 0;
    background: var(--darkgrey);
    color:#fff;
    font-family:'Poppins', sans-serif;
    overflow-x: hidden;
}