.hero-container {
    height: 100vh;
    width: 100%;
    background-size: cover;
    object-fit: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
    min-height: 900px;
}
.hero-bg-image {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: center;
    position: absolute;
    min-height: 850px;
}
.hero-bg-text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    min-height: 850px;
    z-index: 111;
}
.hero-bg-text h1 {
    margin: 0;
    font-size: calc(3rem + (53 - 16) * ((100vw - 320px) / (1600 - 320)));
    text-transform: uppercase;
}
.hero-bg-text p {
    font-size: calc(1.5rem + (31 - 12) * ((100vw - 320px) / (1600 - 320)));
    margin: 0;
}
@media(max-width:612px) {
    .hero-container {
        min-height: 600px;
    }
}