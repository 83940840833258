
  .card {
    border-radius: 10px;
    width: 100%;
    height: 120px;
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color:black;
    padding: 1rem;
    text-align: center;
    filter: drop-shadow(20px 5px 4px rgb(29, 29, 29));
  }
  .buttons button {
    margin: 10px;
    background-color: var(--darkblue);
    border: transparent;
    padding: 7px;
    height: 50px;
    width: 50px;
    border-radius: 500px;
    color:white;
  }
  .main-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .wrapper {
    display: flex;
    margin: 20px;
  }
  .card img {
    width: 100px;
    height: 100px;
  }
  @media(max-width:464px) {
    .card img {
      width: 80px;
      height: 80px;
    }
  }