header {
    position: absolute;
    top: 0;
    z-index: 4;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 5px 15px;
}

header ul {
    display: flex;
    justify-content: center;
    gap: 2rem;
    list-style: none;
    padding: 0;
    margin: 0;
}

header ul li a {
    text-decoration: none;
    color: #fff;
    font-size: 1.2rem;
    position: relative;
    display: block;
    padding: 1.5rem 0rem;
    width: 100px;
    text-align: center;
    font-weight: 600;
}

header ul li a:hover {
    color: var(--darkblue);
}

header ul li a:before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    height: 2px;
    width: 0;
    background: var(--darkblue);
}

header ul li a:hover:before {
    width: 100%;
}

.menu-open {
    display: none;
    position: absolute;
    right: 25px;
    top: 25px;
    font-size: 2rem;
}
.menu-close {
    color: #000;
    font-size: 2rem;
    position: absolute;
    right: 20px;
    top: 20px;
    display: none;
}
.menu-close:hover, .menu-open:hover {
    cursor: pointer;
}
.show-nav {
    transform: translateX(0);
    transition: .7s;
}
@media(max-width:612px) {
    .menu-open {
        display: block;
    }

    .menu-close {
        display: block;
    }
    header ul {
        display: block;
        position: fixed;
        right: 0;
        top: 0;
        background: #fff;
        width: 50%;
        height: 100vh;
        padding: 4rem 3rem;
        transform: translateX(100%);
        transition: .7s;
        animation: 1s menu forwards;
    }
    @keyframes menu {
        0% {
            display: none;
        }
        100% {
            display: block;
        }
    }
    header ul li a {
        text-align: left;
        padding: 1rem 0rem;
        color: #000;
    }

    header ul li a:before {
        display: none;
    }
}
@media(max-width:500px) {
    header ul {
        width: 70%;
    }
}